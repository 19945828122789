import { Box, Grid, Paper, Checkbox, FormControlLabel } from '@material-ui/core';
import { FC, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { debounce, max } from 'lodash';
import { DateTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import moment from 'moment-business-days';
import { useTranslation } from 'react-i18next';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateTypeEnum, auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { usePlatformContext } from 'routes/platform.context';
import { AuctionType, MethodDispute } from 'clients/manager/interfaces/auction-notice.interface';
import { BiddingStageEnum } from 'common/enums/bidding-stage.enum';
import { formatDate } from 'common/utils';
import { ProcessPublicationProps } from './props';
import ExpandedContentCard from '../../../expand-content-card';
import { useProcessFormContext } from '../../../../context/process-form.context';
import ProcessContentHeader from '../process-content-header';
import {
    getPublicationFormLabels,
    getPublicationFormLabelsUnenforceability,
} from '../../../../context/schema/publication-form-schema';
import {
    disableField,
    inInternalPhase,
    processRules,
    processUtils,
} from '../../../../process-utils';

const ProcessPublication: FC<ProcessPublicationProps> = ({ disabledExpand }) => {
    const { platform } = usePlatformContext();
    const { processForm, auctionTypeRules, setPublishDateError, process } = useProcessFormContext();
    const { t } = useTranslation();

    const disableValidation = !!processForm?.values.auctionPublishedDate;

    const minDisputeDaysFromToday =
        Number(auctionTypeRules?.generalSettings?.cadastro?.antecedenciaDisputa) || 0;

    const minDisputeDaysFromAuctionStartDate =
        Number(auctionTypeRules?.generalSettings?.cadastro?.minimoPrazoDisputa) || 0;

    const getStartDateTimeDisputeMinimal = useCallback(() => {
        let diff: number = minDisputeDaysFromToday;

        if (processForm?.values.auctionStartDate) {
            diff = max([minDisputeDaysFromToday, minDisputeDaysFromAuctionStartDate]) as number;

            const minDate = moment(processForm?.values.auctionStartDate)
                .businessAdd(diff)
                .valueOf();

            if (
                (!process?.biddingStageId || process.biddingStageId < BiddingStageEnum.dispute) &&
                minDisputeDaysFromToday > 0 &&
                minDate < moment().valueOf()
            ) {
                return moment().businessAdd(minDisputeDaysFromToday).toISOString();
            }

            return moment(minDate).toISOString();
        }

        return moment().businessAdd(diff).toISOString();
    }, [
        processForm?.values.auctionStartDate,
        minDisputeDaysFromToday,
        minDisputeDaysFromAuctionStartDate,
    ]);

    const [startDateTimeDisputeMinimalDate, setStartDateTimeDisputeMinimalDate] = useState<string>(
        getStartDateTimeDisputeMinimal()
    );

    const defaultInpugationDate = moment(processForm?.values.deadlineImpugnment).toISOString();
    const defaultClarificationDate = moment(
        processForm?.values.deadlineClarification
    ).toISOString();

    const [selectedDisputeDate, setSelectedDisputeDate] = useState<moment.Moment | null>(
        processForm?.values.startDateTimeDispute
            ? moment(processForm?.values.startDateTimeDispute)
            : null
    );

    const [selectedPublishDate, setSelectedPublishDate] = useState<moment.Moment | null>(
        processForm?.values.auctionStartDate ? moment(processForm?.values.auctionStartDate) : null
    );

    useEffect(() => {
        setSelectedPublishDate(
            processForm?.values.auctionStartDate
                ? moment(processForm?.values.auctionStartDate)
                : null
        );
    }, [processForm?.values.auctionStartDate]);

    useEffect(() => {
        setSelectedDisputeDate(
            processForm?.values.startDateTimeDispute
                ? moment(processForm?.values.startDateTimeDispute)
                : null
        );
    }, [processForm?.values.startDateTimeDispute]);

    const showImpugnationDate = processRules.visibleImpugnationDate(processForm?.values);
    const showClarificationDate = processRules.visibleClarificationDate(
        processForm?.values,
        auctionTypeRules
    );

    const getContestationAndClarificationDate = async (startDisputeDate: number) => {
        if (!auctionTypeRules?.id) {
            return;
        }

        try {
            const response = await auctionNoticeRequests.doCalculateDate(
                auctionTypeRules.id,
                startDisputeDate,
                [DateTypeEnum.impugnment, DateTypeEnum.clarification]
            );
            processForm?.setFieldValue(
                'deadlineImpugnment',
                moment(response.data.impugnment).toISOString()
            );
            processForm?.setFieldValue(
                'deadlineClarification',
                moment(response.data.clarification).toISOString()
            );
        } catch (e) {
            return null;
        }
    };

    const getDisputeDate = async (auctionStartDate: number) => {
        if (!auctionTypeRules?.id) {
            return;
        }
        try {
            const response = await auctionNoticeRequests.doCalculateDate(
                auctionTypeRules.id,
                auctionStartDate,
                [DateTypeEnum.dispute]
            );
            setStartDateTimeDisputeMinimalDate(moment(response?.data?.dispute).toISOString());
        } catch (e) {
            return null;
        }
    };

    const onPublishDateChanged = (date: MaterialUiPickersDate) => {
        setSelectedPublishDate(date);

        ReactDOM.unstable_batchedUpdates(() => {
            if (date?.isValid()) {
                processForm?.setFieldValue('auctionStartDate', moment(date).toISOString());
                getDisputeDate(moment(date).valueOf());
                if (
                    moment(startDateTimeDisputeMinimalDate).valueOf() <
                    moment(processForm?.values.startDateTimeDispute).valueOf()
                ) {
                    processForm?.setFieldValue('startDateTimeDispute', null);
                    processForm?.setFieldValue('deadlineImpugnment', null);
                    processForm?.setFieldValue('deadlineClarification', null);
                }
            } else {
                processForm?.setFieldValue('auctionStartDate', null);
                processForm?.setFieldValue('deadlineImpugnment', null);
                processForm?.setFieldValue('deadlineClarification', null);
            }
        });
    };

    const setErrorInvalidDisputeDate = (date: MaterialUiPickersDate | string) => {
        if (!inInternalPhase(process?.biddingStageId) || disableValidation) {
            processForm?.setErrors({ startDateTimeDispute: undefined });
            setPublishDateError(false);
            return;
        }
        if (
            formatDate(date, 'YYYY/MM/DD').valueOf() <
            formatDate(startDateTimeDisputeMinimalDate, 'YYYY/MM/DD').valueOf()
        ) {
            processForm?.setErrors({ startDateTimeDispute: 'Invalid dispute date' });
            setPublishDateError(true);
        } else {
            processForm?.setErrors({ startDateTimeDispute: undefined });
            setPublishDateError(false);
        }
    };

    useEffect(() => {
        if (processForm?.values?.startDateTimeDispute) {
            setErrorInvalidDisputeDate(processForm.values.startDateTimeDispute);
        }
    }, [
        processForm?.values?.auctionStartDate,
        processForm?.values?.startDateTimeDispute,
        startDateTimeDisputeMinimalDate,
    ]);

    const onDisputeDateChanged = (date: MaterialUiPickersDate) => {
        setSelectedDisputeDate(date);

        ReactDOM.unstable_batchedUpdates(() => {
            if (date?.isValid()) {
                setErrorInvalidDisputeDate(date);
                processForm?.setFieldValue('startDateTimeDispute', moment(date).toISOString());
                if (showImpugnationDate || showClarificationDate) {
                    getContestationAndClarificationDate(moment(date).valueOf());
                }
            } else {
                processForm?.setFieldValue('startDateTimeDispute', null);
                processForm?.setFieldValue('deadlineImpugnment', null);
                processForm?.setFieldValue('deadlineClarification', null);
            }
        });
    };

    const onHideSearchsChanged = debounce((_: React.ChangeEvent<{}>, checked: boolean) => {
        processForm?.setFieldValue('hideSearches', Number(checked));
    }, 0);

    useEffect(() => {
        setStartDateTimeDisputeMinimalDate(getStartDateTimeDisputeMinimal());
    }, [
        minDisputeDaysFromToday,
        minDisputeDaysFromAuctionStartDate,
        processForm?.values.auctionStartDate,
    ]);

    const isUnenforceability = processForm?.values?.auctionType === AuctionType.unenforceability;
    function getPartialProcessFormLabels() {
        return isUnenforceability
            ? getPublicationFormLabelsUnenforceability()
            : getPublicationFormLabels();
    }

    const isDispenseWithoutDipute = processUtils.isDispenseWithoutDispute(processForm?.values);
    const dateLabel = isDispenseWithoutDipute ? t('term.session-date') : t('term.dispute-date');

    return (
        <Box mt={2} mb={2}>
            <Paper variant='outlined' elevation={2}>
                <ExpandedContentCard
                    defaultExpanded={false}
                    disabled={disabledExpand}
                    header={
                        <ProcessContentHeader
                            title={t('term.publication')}
                            partialProcessFormLabels={getPartialProcessFormLabels()}
                        />
                    }
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>
                            <KeyboardDateTimePicker
                                fullWidth
                                color='primary'
                                inputVariant='outlined'
                                label={t('term.official-publication')}
                                value={selectedPublishDate}
                                format='DD/MM/YYYY HH:mm'
                                ampm={false}
                                onChange={onPublishDateChanged}
                                okLabel={t('term.confirm')}
                                cancelLabel={t('term.cancel')}
                                invalidDateMessage={t('info.invalid-format', { ns: 'validation' })}
                                invalidLabel={t('info.invalid-date', { ns: 'validation' })}
                                disabled={isUnenforceability}
                            />
                        </Grid>

                        {!isUnenforceability ? (
                            <Grid item xs={12} lg={3}>
                                <KeyboardDateTimePicker
                                    fullWidth
                                    color='primary'
                                    inputVariant='outlined'
                                    label={dateLabel}
                                    value={selectedDisputeDate}
                                    format='DD/MM/YYYY HH:mm'
                                    ampm={false}
                                    onChange={onDisputeDateChanged}
                                    okLabel={t('term.confirm')}
                                    cancelLabel={t('term.cancel')}
                                    minDate={startDateTimeDisputeMinimalDate}
                                    invalidDateMessage={t('info.invalid-format', {
                                        ns: 'validation',
                                    })}
                                    invalidLabel={t('info.invalid-date', { ns: 'validation' })}
                                    minDateMessage={
                                        !inInternalPhase(process?.biddingStageId) ||
                                        disableValidation
                                            ? null
                                            : t('info.min-date', {
                                                  value: moment(
                                                      startDateTimeDisputeMinimalDate
                                                  ).format('DD/MM/YYYY'),
                                                  ns: 'validation',
                                              })
                                    }
                                    disabled={disableField('startDateTimeDispute', process)}
                                />
                            </Grid>
                        ) : null}

                        {showImpugnationDate ? (
                            <Grid item xs={12} lg={3}>
                                <DateTimePicker
                                    fullWidth
                                    color='primary'
                                    inputVariant='outlined'
                                    label={t('term.inpugnation-deadline')}
                                    value={defaultInpugationDate}
                                    disabled
                                    format='DD/MM/YYYY HH:mm'
                                    ampm={false}
                                    okLabel={t('term.confirm')}
                                    cancelLabel={t('term.cancel')}
                                    onChange={() => {}}
                                />
                            </Grid>
                        ) : null}
                        {showClarificationDate ? (
                            <Grid item xs={12} lg={3}>
                                <DateTimePicker
                                    fullWidth
                                    color='primary'
                                    inputVariant='outlined'
                                    label={t('term.clarification-deadline')}
                                    value={defaultClarificationDate}
                                    disabled
                                    format='DD/MM/YYYY HH:mm'
                                    ampm={false}
                                    okLabel={t('term.confirm')}
                                    cancelLabel={t('term.cancel')}
                                    onChange={() => {}}
                                />
                            </Grid>
                        ) : null}
                        {!!platform?.generalSettings?.process?.allowHideProcess && (
                            <Grid item xs={12}>
                                <FormControlLabel
                                    onChange={onHideSearchsChanged}
                                    control={
                                        <Checkbox
                                            defaultChecked={Boolean(
                                                processForm?.values.hideSearches
                                            )}
                                        />
                                    }
                                    disabled={disableField('hideSearches', process)}
                                    checked={Boolean(processForm?.values.hideSearches)}
                                    label={t('info.hide-process-public-area')}
                                />
                            </Grid>
                        )}
                    </Grid>
                </ExpandedContentCard>
            </Paper>
        </Box>
    );
};

export default ProcessPublication;
