import { doRequest, managerApiInstance } from 'clients/api';
import { ProcessRelatoryType } from 'modules/process/interfaces/process-relatory-type';
import { getValidFieldsToUpdate } from 'modules/process/context/process-fields-to-compare';
import { AxiosRequestConfig } from 'axios';
import { OkResponse } from '../interfaces/ok.interface';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import {
    AuctionNoticeOrganizationResume,
    AuctionType,
    OriginOfFinancialResourceType,
    TypeValueBidTypes,
    ItemGroupingType,
    AuctionNotice,
    AuctionNoticeMembers,
    TypeCancel,
    DetailedAutcionNotice,
    AuctionNoticeOrganization,
    GetSearchResumeResponse,
    CreateProcessResponse,
    DoVerifyIfAllProposalsIsClassifiedBody,
    DoVerifyIfAllProposalsIsClassifiedResponse,
    DoApproveSuppliersBody,
    DoApproveSuppliersResponse,
    GetExtraFieldsReponse,
} from './interfaces/auction-notice.interface';
import { ProcessInvite } from './interfaces/process-invite.interface';
import { ProcessImpugnamentAndClarificationDate } from './interfaces/process-impugnament-clarification-date.interface';

export interface ListProcessesFromOrganizationFilters {
    offset?: number;
    limit?: number;
    params?: {
        q?: string;
        auctionType?: AuctionType;
        disputeDate?: number;
        orderBy?: string;
    };
}

export interface ListAvailableProcessesForProviderParams {
    offset?: number;
    limit?: number;
    params?: {
        search?: string;
    };
}

export interface AuctionNoticeForProvider
    extends Omit<AuctionNotice, 'organizationName' | 'organizationUnitName'> {
    organization: {
        id: number;
        organizationName: string;
        platform: string;
    };
    organizationUnit: {
        id: number;
        organizationUnitName: string;
    };
}

export enum ShortFilterEnum {
    favorite = 'favorite',
    suggestion = 'suggestion',
    proposal = 'proposal',
}

export interface DoSearchAuctionNoticeFilters {
    shortFilter?: ShortFilterEnum;
    auctionType?: any;
    legalSupportId?: number;
    biddingStageId?: number;
    state?: string;
    ibgeCodes?: number[];
    supliesProviders?: number[];
    startDate?: number;
    endDate?: number;
    search?: string;
}

export interface DoSearchAuctionNoticeArgs {
    filter?: DoSearchAuctionNoticeFilters;
    offset?: number;
}

export interface PaginationArgs {
    offset?: number;
    limit?: number;
}

export interface GetProcessFromOrganization {
    auctionId: number;
}

export interface ListProcessesFromOrganizationWithResumesFilters {
    auctionIds: number[];
}

export interface CreateProcess {
    auctionType?: string;
    auctionNumber?: string | null;
    unenforceableNumber?: string | null;
    accreditationNumber?: string | null;
    budgetAllocationNumber?: string | null;
    itsPriceRegistration?: number | null;
    startDateTimeDispute?: string | null;
    methodDispute?: number | null;
    judgmentCriterion?: number | null;
    rangeSupplierParticipation?: number | null;
    rangeRegionParticipation?: number | null;
    auctionFile?: string | null;
    simpleDescription?: string | null;
    auctionStartDate?: string | null;
    auctionEndDate?: string | null;
    auctionPurchaseYear?: string | null;
    presidentUserId?: number | null;
    competentAuthorityId?: number | null;
    committeeMembers?: string | null;
    biddingStageId?: number | null;
    contractModelId?: number | null;
    supliesProviders?: string | null;
    approveSuppliers?: number | null;
    itsAgreementOrTransfer?: number | null;
    originOfFinancialResource?: OriginOfFinancialResourceType | null;
    typeValueBid?: TypeValueBidTypes | null;
    agreementNumber?: string | null;
    purchaseNumber?: number | null;
    itemGroupingType?: ItemGroupingType | null;
    expirationDatePriceRegistration?: number | null;
    isRideAllowed?: number | null;
    decimalPlaces?: number | null;
    documents?: string | null;
    globalAmount?: number | null;
    rateTypeBid?: number | null;
    disputeDuration?: number | null;
    legalSupportId?: number;
    ruleId?: string;
    deadline: string | null;
    contractInspector?: string | null;
    deliveryWaysAndAddress?: string | null;
}

export interface UpdateProcess extends CreateProcess {
    id: number;
    requiredDocumentsTypesIds?: number[];
}

export interface CancelProcess {
    auctionId: number;
    typeCancel: TypeCancel;
    message: string;
}

export interface ReopenProcess {
    auctionId: number;
    message: string;
}

interface PublishProcessError {
    token: string;
    warnings: { [key: string]: boolean };
}

export enum TypeNextNumber {
    process = 'process',
    accreditation = 'accreditation',
}

export enum DateTypeEnum {
    impugnment = 'impugnment',
    dispute = 'dispute',
    clarification = 'clarification',
}

const BASE_PATH = '/auction-notice';

const auctionNoticeRequests = {
    listProcessesFromOrganization: async (filters?: ListProcessesFromOrganizationFilters) =>
        doRequest<GenericResponse<AuctionNoticeOrganization>>(
            managerApiInstance.post(`/auction-notice/listProcessesFromOrganization`, filters)
        ),
    listProcessesFromOrganizationWithResumes: async (
        filters?: ListProcessesFromOrganizationFilters
    ) =>
        doRequest<GenericResponse<AuctionNoticeOrganizationResume>>(
            managerApiInstance.post(
                `/auction-notice/listProcessesFromOrganizationWithResumes`,
                filters
            )
        ),
    getProcessFromOrganization: async (filters: GetProcessFromOrganization) =>
        doRequest<PlainGenericResponse<any>>(
            managerApiInstance.post(`/auction-notice/getProcessFromOrganization`, filters)
        ),
    createProcess: async (process?: CreateProcess) =>
        doRequest<PlainGenericResponse<AuctionNotice>>(
            managerApiInstance.post(`/auction-notice/createProcess`, process)
        ),
    updateProcess: async (process: UpdateProcess) => {
        const clearedProcess = getValidFieldsToUpdate(process);
        return doRequest<PlainGenericResponse<{ ok: boolean; inviteProcess?: ProcessInvite }>>(
            managerApiInstance.post(`/auction-notice/updateProcess`, clearedProcess)
        );
    },
    listLatestProcessesMembersFromOrganization: async () =>
        doRequest<PlainGenericResponse<AuctionNoticeMembers>>(
            managerApiInstance.post(`/auction-notice/listLatestProcessesMembersFromOrganization`)
        ),
    doSearchAuctionNotice: async (args: DoSearchAuctionNoticeArgs) =>
        doRequest<PlainGenericResponse<AuctionNotice[]>>(
            managerApiInstance.post(`/auction-notice/doSearchAuctionNotice`, args)
        ),
    getSearchResume: async (config?: AxiosRequestConfig<any>) =>
        doRequest<GetSearchResumeResponse>(
            managerApiInstance.post(`${BASE_PATH}/getSearchResume`, undefined, config)
        ),
    publishProcess: async (auctionId: number, participantDocument?: string) =>
        doRequest<PlainGenericResponse<OkResponse> | PublishProcessError>(
            managerApiInstance.post(`/auction-notice/publishProcess`, {
                auctionId,
                participantDocument: participantDocument,
            })
        ),
    doCalculateDate: async (ruleId: string, date: number, type: DateTypeEnum[]) =>
        doRequest<PlainGenericResponse<ProcessImpugnamentAndClarificationDate>>(
            managerApiInstance.post('/auction-notice/doCalculateDate', {
                ruleId,
                date,
                type,
            })
        ),
    cancelProcess: async (cancelProcess: CancelProcess) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post('/auction-notice/cancelProcess', cancelProcess)
        ),
    reopenProcess: async (reopenProcess: ReopenProcess) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post('/auction-notice/reopenProcess', reopenProcess)
        ),
    finishProcess: async (processId: number) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post('/auction-notice/finishProcess', { auctionId: processId })
        ),

    manageSessionProcess: async (processId: number, isCloseOrReopenSession: boolean) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.patch('/auction-notice/manageSessionProcess', {
                processId: processId,
                isCloseOrReopenSession: isCloseOrReopenSession,
            })
        ),

    getTokenToViewRelatory: async (processId: number, relatoryType: ProcessRelatoryType) =>
        doRequest<void>(
            managerApiInstance.post('/auction-notice/getTokenToViewRelatory', {
                auctionId: processId,
                relatoryType,
            })
        ),
    getService: async (processId: number) =>
        doRequest<PlainGenericResponse<string>>(
            managerApiInstance.get(`/auction-notice/${processId}/service`)
        ),
    getAuctionNotice: async (id: number | string, getAfterPublished?: boolean) =>
        doRequest<PlainGenericResponse<DetailedAutcionNotice>>(
            managerApiInstance.post('/auction-notice/getAuctionNoticeById', {
                auctionId: Number(id),
                getAfterPublished: getAfterPublished,
            })
        ),
    getAuctionNoticeMessages: async (id: number | string) =>
        doRequest(
            managerApiInstance.post('/auction-notice/getAuctionMessages', {
                noticeId: id,
            })
        ),
    getNextNumber: async (processId: number, type: TypeNextNumber, ruleId: string | undefined) =>
        doRequest<PlainGenericResponse<{ number: string }>>(
            managerApiInstance.post('/auction-notice/getNextNumber', {
                auctionId: processId,
                type,
                ruleId,
            })
        ),
    requestPublishProcess: async (auctionId: number) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/auction-notice/requestPublishProcess`, { auctionId })
        ),

    openProcessAppealPhase: async (auctionId: number, minutes?: number) =>
        doRequest<PlainGenericResponse<{ deadlineIntentAppeal: string }>>(
            managerApiInstance.post(`/auction-notice/openProcessAppealPhase`, {
                auctionId,
                minutes,
            })
        ),
    startProcessAppealPhase: async (auctionId: number, days?: number) =>
        doRequest<PlainGenericResponse<{ deadlineAppeal: string }>>(
            managerApiInstance.post(`/auction-notice/startProcessAppealPhase`, {
                auctionId,
                days,
            })
        ),
    startCounterReasonPhase: async (auctionId: number, days?: number) =>
        doRequest<PlainGenericResponse<{ deadlineCounterReason: string }>>(
            managerApiInstance.post(`/auction-notice/startCounterReasonPhase`, {
                auctionId,
                days,
            })
        ),
    finishAppealPhase: async (auctionId: number) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/auction-notice/finishAppealPhase`, {
                auctionId,
            })
        ),
    listAvailableProcessesForProvider: async (params: ListAvailableProcessesForProviderParams) =>
        doRequest<PlainGenericResponse<AuctionNoticeForProvider[]>>(
            managerApiInstance.post(`/auction-notice/listAvailableProcessesForProvider`, params)
        ),
    doVerifyIfAllProposalsIsClassified: async (
        body: DoVerifyIfAllProposalsIsClassifiedBody,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<PlainGenericResponse<DoVerifyIfAllProposalsIsClassifiedResponse>>(
            managerApiInstance.post(
                `/auction-notice/doVerifyIfAllProposalsIsClassified`,
                body,
                config
            )
        ),
    doApproveSuppliers: async (body: DoApproveSuppliersBody, config?: AxiosRequestConfig<any>) =>
        doRequest<PlainGenericResponse<DoApproveSuppliersResponse>>(
            managerApiInstance.post(`/auction-notice/doApproveSuppliers`, body, config)
        ),
    getExtraFields: async (processId?: number, config?: AxiosRequestConfig<any>) =>
        doRequest<GenericResponse<GetExtraFieldsReponse>>(
            managerApiInstance.get(`/auction-notice/${processId}/extraFields`, config)
        ),
};

export { auctionNoticeRequests };
